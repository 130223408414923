import React, { Component, Fragment } from "react"
import { graphql } from "gatsby"

import {
  About,
  Contact,
  Clients,
  Hero,
  Layout,
  LoadScreen,
  Projects
} from '../components'
import SEO from "../components/seo";


import '../styles/global.scss';
import '../styles/animations.scss';


class IndexPage extends Component {



  constructor(props) {
    super(props);

    this.state = {
      showLoader: true,
      totalSections: null,
      currentSection: null,
      projects: [],
    }

  }




  handleScroll(e) {
    if (typeof window === 'undefined' || typeof document === 'undefined') return;

    // will result in error on build due to document & window objects missing

    const brand = document.querySelector('.brand')
    const socials = document.querySelector('.social-media');
    const trigger = document.querySelector('.menu-trigger');
    const location = document.querySelector('.location-indicator');

    if (true) {




      if (window.scrollY > (window.innerHeight / 2)) {

        brand.classList.remove('light');
        brand.classList.add('dark');
        trigger.classList.remove('light');
        trigger.classList.add('dark');

      } else {

        brand.classList.remove('dark');
        brand.classList.add('light');
        trigger.classList.add('light');
        trigger.classList.remove('dark');

      }

      if (window.scrollY > (window.innerHeight / 15)) {

        socials.classList.remove('light');
        socials.classList.add('dark');
        location.classList.remove('light');
        location.classList.add('dark');

      } else {

        socials.classList.remove('dark');
        socials.classList.add('light');
        location.classList.remove('dark');
        location.classList.add('light');


      }

      requestAnimationFrame(function () {

      })

    }


  }





  componentDidMount() {


    this.setState(state => ({ ...this.state, projects: this.props.data.allProjectJson.edges }));


    let proj = this.state.projects;

    setTimeout(() => {
      this.setState({ showLoader: false })
    }, 3900);





    document.addEventListener('scroll', this.handleScroll, true);

    const sections = document.querySelectorAll('.section');
    const fadeElemens = document.querySelectorAll('.fade-in');

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: [.2, .6, .95]
    };

    const locationIndicator = document.querySelector('.location-indicator');
    let currentY = window.scrollY;
    let lastY = window.scrollY;
    let lastSection = "";
    if (sections.length) {
      sections.forEach(section => {

        const observer = new IntersectionObserver(entries => {
          if (entries[0].isIntersecting) {

            currentY = window.scrollY;

            if (currentY >= lastY) {
              // scrolling down

              if (entries[0].intersectionRatio < .5) {

                locationIndicator.innerHTML = `<h3 class='location-indicator__text active'>` + entries[0].target.dataset.name + `</h3>`;
              }

            } else {
              // scrolling up
              // will fire twice, when scrolling up, wait until the second fire when component is 60% within view

              if (entries[0].intersectionRatio > .55) {
                //already fired for section
                locationIndicator.innerHTML = `<h3 class='location-indicator__text active'>` + entries[0].target.dataset.name + `</h3>`;

              }
            }
            lastSection = '' + entries[0].target.dataset.name;
            lastY = currentY;

          } else {

          }

        }, options)


        observer.observe(section);

      });

    }



    // ANIMATIONS
    const appearOptions = {
      threshold: .9,
      rootMargin: '0px 0px 0px 0px'
    };
    const appearOnScroll = new IntersectionObserver(
      function (entries, appearOnScroll) {
        entries.forEach(entry => {
          if (!entry.isIntersecting) {
            return;
          } else {
            entry.target.classList.add('appear');
            entry.target.setAttribute('data-appear', 'true');
            entry.target.setAttribute('data-delay',  entry.target.dataset.delay);
            appearOnScroll.unobserve(entry.target);
          }
        })
      }
      , appearOptions);

    const fadeElements = document.querySelectorAll('.fade-in');
    const tSections = document.querySelectorAll('.section');

    if (fadeElements.length) {
      fadeElements.forEach(element => {
        appearOnScroll.observe(element)
      })
    };
    
  }




  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll);
  }









  render() {
    const { projects } = this.state;

    return (


      <Fragment>

          {/* {this.state.showLoader &&  <LoadScreen /> } */}

          
            <Layout
              showHomeMenu={true}
            >

            <SEO title="Home" />
  
            <Hero></Hero>
            <Clients />
            <Projects projects={projects}
            />
            <About></About>
            <Contact></Contact>
            </Layout>
           
          

      </Fragment>


    );





  }
}


export const pageQuery = graphql`
  {
    allProjectJson {
      edges {
        node {
          title
          subtitle
          excerpt
          slug
          date
          blobFill
          colours
          featuredImage {
            id
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 200
                placeholder: BLURRED
              )
              fluid(maxWidth:300) {
                base64
                tracedSVG
                srcWebp
                srcSetWebp
                originalImg
                originalName
              }
            }
          }
        }
      }
    }
  }
`;



export default IndexPage;